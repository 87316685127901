import { Component, OnInit } from '@angular/core';
import { AuthService } from '_services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  email: string;
  password: string;

  constructor(
    public authService: AuthService,
    private router: Router) {}

  ngOnInit() { }

}
