import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-draft-mode',
  template: `
  <button mat-flat-button color="accent" (click)="launchDraftMode()">Launch NPC Preview</button>
  `,
  styles: []
})
export class DraftModeComponent {

  constructor(
    private route: ActivatedRoute,
    public auth: AuthService
  ) { }

  async launchDraftMode() {
    const schoolId = this.route.snapshot.params['schoolId'];
    const user = await this.auth.currentUser();
    const url = environment.draftModeUrl + schoolId + '?draft=' + user.uid;
    window.open(url, '_blank');
  }
}
