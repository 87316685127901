import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublishingService } from '../../_services/publishing.service';
import { UnivPrefsService } from '_services/univ-prefs.service';

@Component({
  selector: 'app-publish-preferences',
  templateUrl: './publish-preferences.component.html',
  styleUrls: ['./publish-preferences.component.css']
})

export class PublishPreferencesComponent implements OnInit {

  constructor(
    private ps: PublishingService,
    private route: ActivatedRoute,
    public ups: UnivPrefsService
  ) { }

  ngOnInit() {
  }
  resetPreferences() {
    const schoolId = this.route.snapshot.params['schoolId'];
    this.ps.copyToDraft(schoolId);
    // this.ps.copyLogoToDraft(this.schoolId);
  }
  publishPreferences() {
    const schoolId = this.route.snapshot.params['schoolId'];
    this.ps.publishDraft(schoolId);
  }
}
