import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UnivPrefsService } from '_services/univ-prefs.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.css']
})
export class BrandingComponent implements OnInit {
  brandingForm: UntypedFormGroup;
  documentPath: string;
  state: string;
  logoSize = new BehaviorSubject(null);
  welcomeSize = new BehaviorSubject(null);

  contentChoice = 'welcomeDisclaimer';
  constructor(
    private titleService: Title,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    public ups: UnivPrefsService
  ) {
    this.titleService.setTitle('Branding' + ' | NPC Admin');
  }

  ngOnInit() {
    this.brandingForm = this.fb.group({
      headerType: [''],
      headerColor: [''],
      headerGradient: [''],
      headerBottomBorderStyle: [''],
      backgroundType: [''],
      backgroundColor: [''],
      backgroundGradient: [''],
      panelColor: [''],
      footerColor: [''],
      headerShrink: ['']
    });

    const schoolId = this.route.snapshot.params['schoolId'];
    this.documentPath = 'preferences_draft/' + schoolId;

    if (this.headerTypeValue === '') {
      this.brandingForm.controls['headerType'].setValue('hSolid');
    }
    if (this.bkgTypeValue === '') {
      this.brandingForm.controls['backgroundType'].setValue('bgSolid');
    }
  }


  changeHandler(e) {
    this.state = e;
  }

  uploadFile(event: Event, type: 'logo' | 'header' | 'page' | 'welcome') {
    if (event.target instanceof HTMLInputElement) {
      const files = event.target.files as FileList;
      const schoolId = this.route.snapshot.params['schoolId'];
      const file = files.item(0);
      const size = Math.round(file.size / 1000);
      // If file.size is greater than 200K warn user.  Prohibit sizes > 2M
      if (type === 'welcome' && size > 2000) {
        alert('The file you are uploading is roughly ' + size + 'k. Please use squoosh.app to get your file under 2000K');
      } else if (size > 500) {
        alert('The file you are uploading is roughly ' + size + 'k. Please use squoosh.app to get your file under 500K');
      } else {
        this.ups.uploadImageFile(schoolId, file, type);
      }
    }
  }

  removeImageRef(type: string) {
    const schoolId = this.route.snapshot.params['schoolId'];
    this.ups.removeImageRef(schoolId, type);
  }

  check(event: Event, type: 'logo' | 'welcome') {
    if (event.target instanceof HTMLImageElement) {
      const image = event.target as HTMLImageElement;
      const size = { width: image.naturalWidth, height: image.naturalHeight };

      switch (type) {
        case 'logo':
          this.logoSize.next(size);
          break;
        case 'welcome':
          this.welcomeSize.next(size);
          break;
      }
    }
  }

  get headerTypeValue(): string {
    return this.brandingForm.controls['headerType'].value;
  }
  get headerGradientValue(): string {
    return this.brandingForm.controls['headerGradient'].value;
  }
  get headerColorValue(): string {
    return this.brandingForm.controls['headerColor'].value;
  }
  get backgroundColorValue(): string {
    return this.brandingForm.controls['backgroundColor'].value;
  }
  get bkgTypeValue(): string {
    return this.brandingForm.controls['backgroundType'].value;
  }
  get bkgGradientValue(): string {
    return this.brandingForm.controls['backgroundGradient'].value;
  }
  get headerBottomBorderValue(): string {
    return this.brandingForm.controls['headerBottomBorderStyle'].value;
  }
  get panelColorValue(): string {
    return this.brandingForm.controls['panelColor'].value;
  }
  get footerColorValue(): string {
    return this.brandingForm.controls['footerColor'].value;
  }

}
