import { Component } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-userform',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent {

  userForm: UntypedFormGroup;
  passReset = false;



  constructor(private fb: UntypedFormBuilder, private auth: AuthService) {
    this.buildForm();
  }

  get email() { return this.userForm.get('email'); }

  signup() {
    if (this.userForm?.valid) {
      this.auth.signup(this.userForm.controls['email'].value, this.userForm.controls['password'].value)
        .catch(err => {
          alert('This email is already registered.  Please try logging in or reset password.');
        });
    } else {
      alert('Please enter a valid email and password to request access');
    }
  }

  login(): void {
    if (this.userForm?.valid) {
      this.auth.login(this.email.value, this.userForm.controls['password'].value);
    } else {
      alert('Please enter a valid email and password to login');
    }
  }

  resetPassword() {
    this.auth.resetPassword(this.email.value)
      .then(() => this.passReset = true)
      .catch(err => { console.log(err); alert('No account exists for ' + this.email.value); });
  }

  buildForm(): void {
    this.userForm = this.fb.group({
      'email': ['', [
        Validators.required,
        Validators.email
      ]
      ],
      'password': ['', [
        Validators.required,
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(6)
      ]
      ],
    });

  }


}
