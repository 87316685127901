import { Component, HostListener, OnInit, Inject } from '@angular/core';
import { AuthService } from '_services/auth.service';
import { UnivPrefsService } from '_services/univ-prefs.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { environment } from 'environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public small = false;
  public headerHeight = 100;
  public devServer = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    public authService: AuthService,
    public router: Router,
    public ups: UnivPrefsService) { }

  ngOnInit() {
    // const schoolId = this.route.snapshot.params['schoolId'];
    // const schoolId = location.pathname.split('/')[1];
    this.devServer = environment.database === 'dev';
  }


  logout() {
    this.ups.unload();
    this.authService.logout();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const fullHeight = 100; // Same as headerHeight default above
    const smallHeight = 50;
    const buffer = 10;

    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (number >= buffer && number <= smallHeight + buffer) {
      this.headerHeight = fullHeight + buffer - number;
    } else if (number > smallHeight + buffer) {
      this.headerHeight = smallHeight;
    } else if (number < buffer) {
      this.headerHeight = fullHeight;
    }
  }

}
