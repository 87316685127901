<ng-container *ngIf="schoolId">
  <nav class="sidebar" aria-label="NPC Admin Navigation">
    <div class="sidebar-sticky">
      <p></p>
      <ul class="nav">

        <li *ngIf="(user | async)?.admin" class="nav-item">
          <a class="nav-link" [routerLink]="'/schools'" routerLinkActive="active">
            CHANGE SCHOOLS
          </a>
          <hr>
        </li>

        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/dashboard'" routerLinkActive="active" #rla1="routerLinkActive" attr.aria-current="{{rla1.isActive}}">
            <img class="icon" src="./assets/images/icons8-speed.svg" alt="">DASHBOARD
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/inspector'" routerLinkActive="active" #rla2="routerLinkActive" attr.aria-current="{{rla2.isActive}}">
            <img class="icon" src="./assets/images/icons8-telescope.svg" alt="">INSPECTOR
          </a>
          <hr>
        </li>

        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/branding'" routerLinkActive="active" #rla3="routerLinkActive" attr.aria-current="{{rla3.isActive}}">
            <img class="icon" src="./assets/images/icons8-rgb_circle_3.svg" alt="">BRANDING
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/configuration'" routerLinkActive="active" #rla4="routerLinkActive" attr.aria-current="{{rla4.isActive}}">
            <img class="icon" src="./assets/images/icons8-settings.svg" alt="">CONFIGURATION
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/content'" routerLinkActive="active" #rla5="routerLinkActive" attr.aria-current="{{rla5.isActive}}">
            <img class="icon" src="./assets/images/icons8-web_design.svg" alt="">CONTENT
          </a>
          <hr>
        </li>

        <!-- <app-aid-year></app-aid-year> -->

        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/questions'" routerLinkActive="active" #rla6="routerLinkActive" attr.aria-current="{{rla6.isActive}}">
            <img class="icon" src="./assets/images/icons8-ask_question.svg" alt="">QUESTIONS
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/costs'" routerLinkActive="active" #rla7="routerLinkActive" attr.aria-current="{{rla7.isActive}}">
            <img class="icon" src="./assets/images/icons8-money.svg" alt="">COSTS
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/packaging'" routerLinkActive="active" #rla8="routerLinkActive" attr.aria-current="{{rla8.isActive}}">
            <img class="icon" src="./assets/images/icons8-open_box.svg" alt="">PACKAGING
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/populations'" routerLinkActive="active" #rla9="routerLinkActive" attr.aria-current="{{rla9.isActive}}">
            <img class="icon" src="./assets/images/icons8-groups.svg" alt="">POPULATIONS
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/tables'" routerLinkActive="active" #rla10="routerLinkActive" attr.aria-current="{{rla10.isActive}}">
            <img class="icon" src="./assets/images/icons8-data_sheet_filled.svg" alt="">TABLES
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/formulas'" routerLinkActive="active" #rla11="routerLinkActive" attr.aria-current="{{rla11.isActive}}">
            <img class="icon" src="./assets/images/icons8-math.svg" alt="">FORMULAS
          </a>
        </li>



        <li *ngIf="(user | async)?.admin" class="nav-item">
          <hr>
          <a class="nav-link" [routerLink]="schoolId + '/superadmin'" routerLinkActive="active" #rla12="routerLinkActive" attr.aria-current="{{rla12.isActive}}">
            <img class="icon" src="./assets/images/icons8-overwatch.svg" alt="">SUPER ADMIN
          </a>
        </li>
        <li *ngIf="(user | async)?.admin" class="nav-item">
          <a class="nav-link" [routerLink]="schoolId + '/questions-admin'" routerLinkActive="active" #rla13="routerLinkActive" attr.aria-current="{{rla13.isActive}}">
            <img class="icon" src="./assets/images/icons8-overwatch.svg" alt="">Q's ADMIN
          </a>
        </li>
        <!-- <li class="nav-item">
              <a class="nav-link" [routerLink]="schoolId + '/inastest'" routerLinkActive="active">
                <img class="icon" src="./assets/images/icons8-overwatch.svg" alt="">INAS TEST
              </a>
            </li> -->


        <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="schoolId + '/options'" routerLinkActive="active">
              <img class="icon" src="./assets/images/icons8-settings.svg" alt="">OPTIONS (Demo)
            </a>
          </li> -->

      </ul>

    </div>
  </nav>
</ng-container>