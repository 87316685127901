import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from '../../_models/user';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  user: Observable<User>;

  constructor(
    private titleService: Title,
    public auth: AuthService,
    private router: Router
  ) {
    this.titleService.setTitle('Home' + ' | NPC Admin');
  }

  ngOnInit() {
    this.user = this.auth.user
      .pipe(
        tap(user => {
          if (user) {
            if (user.admin) {
              this.router.navigate(['schools']);
              return;
            }
            if (user.schools) {
              // If user only has one school, navigate to that school.
              if (user.schools.length === 1) {
                this.router.navigate([user.schools[0] + '/dashboard']);
                return;
              }
              // If user has multiple schools or is an admin, navigate to the schools page.
              if (user.schools.length > 1) {
                this.router.navigate(['schools']);
                return;
              }
            }
          }
        })
      )
  }

}
