<div *ngIf="authService.noUser | async">
  <app-userform></app-userform>
</div>

<div *ngIf="(authService.user | async) && !(authService.noUser | async)" class="wrapper">
  <app-header></app-header>
  <section class="content">
    <app-navmenu></app-navmenu>
    <main id="content" class="panel" tabindex="-1">
      <router-outlet></router-outlet>
    </main>
  </section>
</div>
