import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Result, Session } from '../../_models/result';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../_services/auth.service';


@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
})
export class SessionsComponent implements OnInit {

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }

  schoolId: string;
  sessions: Observable<Session[]>;
  selected: Session = undefined;

  ngOnInit() {
    this.schoolId = this.route.snapshot.params['schoolId'];
    this.loadSessions(this.schoolId);
  }

  private loadSessions(schoolId) {
    const sessionCol = this.afs.collection<Session>('sessions',
      ref => ref.where('univName', '==', schoolId)
        .orderBy('savedAt', 'desc')
        .limit(20)
    );
    this.sessions = sessionCol.snapshotChanges().pipe(
      map(qs => {
        return qs.map(a => {
          const data = a.payload.doc.data() as Session;
          const id = a.payload.doc.id; // This is needed if we remove "id" from the record
          return { id, ...data };
        });
      }));
  }
  isNumber(value): boolean {
    return ((value != null) && !isNaN(Number(value.toString())));
  }

  toPlainObject(value: any): any {
    return { ...value };
  }

  public async createAdminSession() {
    if (this.selected) {
      const user = await this.auth.currentUser();

      const adminSession = {
        answers: this.selected.answers,
        preparedAnswers: this.selected.preparedAnswers,
        cbUserId: this.selected.cbUserId,
        savedAt: this.selected.savedAt,
        univName: this.selected.univName,
        userId: user.uid
      };

      this.afs.collection('sessions_admin').add(adminSession);
    }
  }

}
