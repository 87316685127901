import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../_services/auth.service';
import { UnivPrefsService } from '../_services/univ-prefs.service';
import { SchoolsService } from '../_services/schools.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.css']
})
export class SchoolsComponent implements OnInit {

  userSchools: Observable<string[]>;

  constructor(
    private titleService: Title,
    public auth: AuthService,
    public sa: SchoolsService,
    private ups: UnivPrefsService,
    private router: Router
  ) {
    this.titleService.setTitle('Home' + ' | NPC Admin');
  }

  ngOnInit() {
    this.ups.unload();
    this.userSchools = this.auth.user.pipe(
      map(user => {
        if (user) {
          if (user.schools) {
            if (user.schools.length === 1) {
              this.router.navigate([user.schools[0] + '/dashboard']);
            }
          }
          return (user.schools || []);
        } else {
          return [];
        }
      })
    );
  }

  getCodes(schools) {
    prompt('Use this list of school codes for conversion purposes', this.sa.buildAllSchoolsString(schools));
  }
}



