// Core Module
// ONLY provide services from this module

import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// @angular/fire/ imports
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

// Custom services
import { UnivPrefsService } from './univ-prefs.service';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { QuestionsService } from './questions.service';
import { PublishingService } from './publishing.service';
import { InspectorService } from './inspector.service';
import { CalcConfigService } from './calc-config.service';
import { SelectorsService } from './selectors.service';
import { LoggingService } from './logging.service';

@NgModule({
    imports:
        [
            AngularFireModule.initializeApp(environment.firebaseEnv),
            AngularFirestoreModule, // .enablePersistence(),
            AngularFireAuthModule,
            AngularFireStorageModule
        ],
    providers:
        [
            AuthService,
            UnivPrefsService,
            AuthGuard,
            QuestionsService,
            PublishingService,
            InspectorService,
            CalcConfigService,
            SelectorsService,
            LoggingService,
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class CoreModule { }
