import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { User } from '../_models/user';

@Injectable()
export class AuthService {

  user: Observable<User>;
  noUser = new BehaviorSubject(false);

  constructor(
    private firebaseAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router) {
    this.user = firebaseAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          if (user.isAnonymous) {
            // logs out a user that was cached from the student application
            this.logout();
          }
          this.noUser.next(false);
          return this.afs.doc<User>(`admin_users/${user.uid}`).valueChanges();
        } else {
          this.noUser.next(true);
          return of(null);
        }
      })
    );
  }

  currentUser(): Promise<User> {
    return this.user.pipe(first()).toPromise();
  }

  signup(email: string, password: string): Promise<void> {
    return this.firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(newUser => {
        return this.createUserDoc(newUser.user);
      });
  }

  // 1. Enforce admin false
  // 2. Enforce schools null

  createUserDoc(aUser) {
    const userRef = this.afs.doc(`admin_users/${aUser.uid}`);
    const data: User = {
      uid: aUser.uid,
      name: aUser.email,
      email: aUser.email,
      schools: null,
      admin: false
    };
    return userRef.set(data);
  }

  login(email: string, password: string) {
    this.firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(value => {
        console.log('User logged in successfully');
      })
      .catch(err => {
        console.log('Auth server says:', err.message);
        alert(err.message);
      });
  }

  logout(): Promise<void> {
    this.noUser.next(true);
    return this.firebaseAuth
      .signOut()
      .then((status) => {
        this.router.navigate([''], { replaceUrl: true });
        window.location.reload();
      });
  }

  resetPassword(email: string): Promise<void> {
    return this.firebaseAuth.sendPasswordResetEmail(email);
  }

}

// Code from: https://alligator.io/angular/firebase-authentication-@angular/fire//
