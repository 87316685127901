export const environment = {
  production: true,
  database: 'production',
  firebaseEnv: {
    apiKey: 'AIzaSyBRyI3wIMkmq7TcyL7t-TORw_UaiuYrMQc',
    authDomain: 'rewirenpc.firebaseapp.com',
    databaseURL: 'https://rewirenpc.firebaseio.com',
    projectId: 'rewirenpc',
    storageBucket: 'rewirenpc.appspot.com',
    messagingSenderId: '203397110020'

  },
  draftModeUrl: 'http://npc.rewiretech.com/app/' // Production Student
};
