import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CalcConfigService } from '_services/calc-config.service';
import { SelectorsService } from '_services/selectors.service';
import { Table } from '../../_models/table';

import { filter } from 'lodash-es';
@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})
export class TablesComponent implements OnInit {
  tableName = '';
  table: Table;
  tableCopy: Table;
  tabIndex = 0;
  editMode = false;
  schoolId: string;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public cc: CalcConfigService,
    private ss: SelectorsService
  ) {
    this.titleService.setTitle('Tables' + ' | NPC Admin');
  }

  ngOnInit() {
    this.schoolId = this.route.snapshot.params['schoolId'];
  }

  getSelectorName(id: string): string {
    const selector = this.ss.getSelector(id);
    if (selector) {
      return selector.label;
    }
  }

  setMode(mode: 'edit' | 'save' | 'cancel' | 'delete') {
    switch (mode) {
      case 'edit':
        this.tableCopy = JSON.parse(JSON.stringify(this.table)); // lodash has a deep clone function
        this.editMode = true;
        break;
      case 'cancel':
        // automatically loses the table copy
        this.editMode = false;
        if (this.tableCopy.keyId === undefined && this.tableCopy.formulaId === undefined) {
          // Cancelled on a table that was not set up - remove table
          this.clickedRow();
        }
        break;
      case 'delete':
        this.editMode = false;
        // Check to see if table is being used before deletion
        if (filter(this.cc.usedBy.value, { 'entityId': this.table.tableId, 'entityType': 'Table' }).length === 0) {
          this.cc.deleteTable(this.schoolId, this.table);
        } else {
          alert('This table is being used. You need to remove uses before deleting the table');
        }
        this.clickedRow();
        break;
      case 'save':
        this.table = this.tableCopy;
        if (this.tableCopy.keyId !== undefined || this.tableCopy.formulaId !== undefined) {
          this.cc.saveTable(this.schoolId, this.tableCopy);
        } else {
          this.clickedRow();
        }
        this.editMode = false;
        break;
    }
  }

  changeTab(event: any) {
    this.tabIndex = event;
    if (this.editMode && this.tabIndex === 0) {
      this.setMode('cancel');
    }
  }

  clickedRow(table?: Table) {
    if (table) {
      this.tableName = table.name;
      this.table = table;
      this.tabIndex = 1;
    } else {
      this.tableName = '';
      this.tabIndex = 0;
    }
  }

  addTable(tables: Table[]) {
    const aTable: Table = {
      tableId: this.cc.getUniqueId(),
      name: 'New Table',
      description: '',
      type: 'Question',
      rangeTest: true
    };
    // Automatically edit this table
    this.clickedRow(aTable);
    this.setMode('edit');
  }

}


