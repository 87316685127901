import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../_services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { TrafficService } from '_services/traffic.service';
import { CalcConfigService } from '_services/calc-config.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  schoolId: string;

  constructor(
    private titleService: Title,
    public auth: AuthService,
    private route: ActivatedRoute,
    public ts: TrafficService,
    public cc: CalcConfigService
  ) {
    this.titleService.setTitle('Dashboard' + ' | NPC Admin');
  }

  ngOnInit() {
    this.schoolId = this.route.snapshot.params['schoolId'];
    this.ts.loadTraffic(this.schoolId);
    // This allows the dashboard to display and then load the calc rules in the background (as opposed to having the guard do it)
    this.cc.loadDraftCalcRules(this.schoolId);
    this.cc.loadPublishedCalcRules(this.schoolId);
  }

}
