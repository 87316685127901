import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { CalcConfigService } from '_services/calc-config.service';

@Component({
  selector: 'app-package-overrides',
  templateUrl: './package-overrides.component.html',
  styleUrls: ['./package-overrides.component.css']
})
export class PackageOverridesComponent implements OnInit {
  documentPath: string;
  poForm: UntypedFormGroup;
  state: string;
  modified = false;
  schoolId: string;

  constructor(
    private fb: UntypedFormBuilder,
    public cc: CalcConfigService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.poForm = this.fb.group({
      //  overrides: this.fb.array([new FormGroup ({name: new FormControl,  cap: new FormControl, floor: new FormControl})]),
      institutionalScholarship: new UntypedFormGroup({
        cap: new UntypedFormControl(),
        floor: new UntypedFormControl(),
      }),
      institutionalGrant: new UntypedFormGroup({
        cap: new UntypedFormControl(),
        floor: new UntypedFormControl(),
      }),
      stateGrant: new UntypedFormGroup({
        cap: new UntypedFormControl(),
        floor: new UntypedFormControl(),
      }),
      studentLoan: new UntypedFormGroup({
        cap: new UntypedFormControl(),
        floor: new UntypedFormControl(),
      }),
      schoolDefinedFund1: new UntypedFormGroup({
        cap: new UntypedFormControl(),
        floor: new UntypedFormControl(),
      }),
      schoolDefinedFund2: new UntypedFormGroup({
        cap: new UntypedFormControl(),
        floor: new UntypedFormControl(),
      })
    }
    );
    this.schoolId = this.route.snapshot.params['schoolId'];
    this.documentPath = 'calcConfig/' + this.schoolId + '/other/package_overrides';
  }
  changeHandler(e) {
    this.state = e;
    if (this.state === 'modified') {
      this.modified = true;
    }
    if (this.state === 'synced') {
      if (this.modified) {
        const overrides = this.poForm.value;
        for (const key of Object.keys(overrides)) {
          if (overrides[key].cap || overrides[key].floor) {
            for (const pack of this.cc.packages.value) {
              if (pack.parent) {
                if (pack.parent.id === key[0].toUpperCase() + key.slice(1)) {
                  pack.parent.cap = overrides[key].cap;
                  pack.parent.floor = overrides[key].floor;
                }
              }
            }
          }
        }
        this.cc.savePackages(this.schoolId, this.cc.packages.value, false);
        this.modified = false;
      }
    }
  }
}



